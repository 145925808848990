import React, { useEffect, useState } from 'react';
import { Select, Row, Col } from 'antd';
import { Dictionary } from '@onaio/utils/dist/types/types';
import { useDispatch, useSelector } from 'react-redux';
import {
  actionPostComponentSettingEdit,
  actionPostComponentErrorAdd,
} from '../../../actions';
import { isGlobalFilter } from '../../helpers/helpers';
import { getDataType, selectOptionsBuilder } from './helpers/helpers';
import { AkukoAPIService } from '../../../../../services/serviceClass';
import { QUERY_API } from '../../../../../configs/env';
import { generateJWTToken } from '../../../utils';
export interface DimensionInputProps {
  componentIndex: number;
  childIndex?: number;
  childProperty?: string;
  itemIndex?: number;
  item: Dictionary;
}

const DimensionInput: React.FC<DimensionInputProps> = (
  props: DimensionInputProps
) => {
  const dispatch = useDispatch();
  const { componentIndex, childIndex, itemIndex, item } = props;
  const {
    property,
    label,
    setType,
    setNestedType,
    typeKey,
    objectKey,
    parents,
    getMinMax,
    setNestedDataType,
    dataTypeKey,
    dataSourceKey,
    nestedDataTypeKey,
    expectedType,
    expectedDataType,
    placeHolder,
    setDataType,
    setDataSource,
    limitSortOptions,
    showGeometryProperties,
  } = item;
  const post = useSelector((state: Dictionary) => state.post);
  const component = post.components[componentIndex];
  const [value, setValue] = useState('');
  let valueType = '';
  const [selectOptions, setSelectOptions] = useState<JSX.Element[]>([]);

  let source = '';
  let propLevel: Dictionary = {};
  const xAxisValue = component.xAxis?.value;
  const seriesValue = component.series?.map(
    (serie: Dictionary) => serie?.value
  );

  if (!parents) {
    source = component?.source;
  }
  if (
    parents &&
    parents.length === 1 &&
    parents[0] === 'layers' &&
    itemIndex !== undefined
  ) {
    source = component?.[parents[0]]?.[itemIndex]?.source;
    propLevel = component?.[parents[0]]?.[itemIndex];
    valueType = propLevel?.[`${property}Type`];
  }
  if (
    parents &&
    parents.length === 1 &&
    parents[0] !== 'layers' &&
    itemIndex !== undefined
  ) {
    source = component?.source;
    propLevel = component?.[parents[0]]?.[itemIndex];
    valueType = propLevel?.[`${property}Type`];
  }
  if (
    parents &&
    parents.length === 2 &&
    childIndex !== undefined &&
    itemIndex !== undefined
  ) {
    source = component?.[parents[0]]?.[childIndex]?.source;
    propLevel =
      component?.[parents[0]]?.[childIndex]?.[parents[1]]?.[itemIndex];
    valueType = propLevel?.[`${property}Type`];
  }
  if (
    parents &&
    parents.length === 2 &&
    component.type === 'button' &&
    childIndex !== undefined &&
    itemIndex !== undefined
  ) {
    source = component.source;
    propLevel = component;
    valueType = propLevel?.[`${property}Type`];
  }
  const sourceObj = post.sources[source];
  let measures = sourceObj?.measures;
  let dimensions = sourceObj?.dimensions;

  useEffect(() => {
    if (value && source && getMinMax && sourceObj) {
      let measures: string[] | [] = [];
      let dimensions: string[] | [] = [];
      const sourceType =
        valueType ||
        getDataType(sourceObj.dimensions, sourceObj.measures, value);
      if (sourceType === 'measure') {
        measures = [`${sourceObj.cube}.${value}`];
      } else if (sourceType === 'dimension') {
        dimensions = [`${sourceObj.cube}.${value}`];
      }
      const query = {
        dimensions: dimensions,
        measures: measures,
        order: {
          [`${sourceObj?.cube}.${value}`]: 'desc',
        },
        limit: 1,
        renewQuery: true,
      };
      generateJWTToken({
        sourceId: sourceObj?.uuid,
        cubeName: sourceObj?.cube,
        refreshKey: sourceObj?.refresh_key,
      }).then((res) => {
        const token = res;
        const headers = {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        };
        const service = new AkukoAPIService(
          QUERY_API,
          '/cubejs-api/v1/load',
          undefined,
          headers
        );
        service
          .create({
            query: query,
          })
          .then((data) => {
            const res = data as Dictionary;
            const queryResult = res.data as Dictionary[];
            if (property !== 'colorField') {
              dispatch(
                actionPostComponentSettingEdit({
                  parents: parents,
                  property: 'maxValue',
                  componentIndex: componentIndex,
                  itemIndex: itemIndex,
                  childIndex: childIndex,
                  objectKey: objectKey,
                  value: Number(queryResult[0][`${sourceObj?.cube}.${value}`]),
                })
              );
            }
          })
          .catch((error) => {
            dispatch(
              actionPostComponentErrorAdd({
                id: component.id,
                name: component.name,
                type: component.type,
                errors: error,
              })
            );
          });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    const geomProperties =
      propLevel?.geometries?.[propLevel.geometryIndex]?.properties;
    if (
      propLevel?.geometryIndex !== undefined &&
      propLevel?.geometries?.length &&
      showGeometryProperties &&
      geomProperties?.length
    ) {
      dimensions = dimensions.filter((dimension: Dictionary) =>
        geomProperties.includes(dimension.value)
      );
      measures = measures.filter((measure: Dictionary) =>
        geomProperties.includes(measure.name)
      );
    }
    const options =
      expectedType === 'dimension'
        ? selectOptionsBuilder(dimensions, expectedDataType, 'dimension')
        : [
            ...selectOptionsBuilder(measures, expectedDataType, 'measure'),
            ...selectOptionsBuilder(dimensions, expectedDataType, 'dimension'),
          ];
    if (xAxisValue && seriesValue.length && limitSortOptions) {
      seriesValue.push(xAxisValue);
      const filteredData = options.filter((option) =>
        seriesValue.includes(option.props?.value)
      );
      setSelectOptions(filteredData);
    } else {
      setSelectOptions(options);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(sourceObj)]);

  useEffect(() => {
    if (
      parents?.length === 2 &&
      itemIndex !== undefined &&
      childIndex !== undefined
    ) {
      const currentValue =
        component?.[parents[0]]?.[childIndex]?.[parents[1]]?.[itemIndex]?.[
          property
        ];
      setValue(currentValue);
    }
    if (parents?.length === 1 && itemIndex !== undefined) {
      const currentValue = component?.[parents[0]]?.[itemIndex]?.[property];
      setValue(currentValue);
    }
    if (!parents) {
      if (objectKey) {
        if (!component[property]) {
          setValue('');
        } else {
          setValue(component[property][objectKey]);
        }
      } else {
        setValue(component[property]);
      }
    }
    if (item.entity === 'post') {
      setValue(post[property]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [post, parents, itemIndex, childIndex, componentIndex]);
  return (
    <Row>
      <Col sm={6}>
        <label>{label}</label>
      </Col>
      <Col sm={18}>
        <Select
          placeholder={placeHolder ? placeHolder : undefined}
          disabled={isGlobalFilter(component, parents, childIndex, itemIndex)}
          showSearch
          allowClear
          value={value ? value : undefined}
          getPopupContainer={() =>
            document.getElementById('drawer-content') as HTMLElement
          }
          onChange={(value: string, e: Dictionary) => {
            if (value) {
              setValue(value);
              if (property !== 'colorField') {
                dispatch(
                  actionPostComponentSettingEdit({
                    parents: parents,
                    property: 'maxValue',
                    componentIndex: componentIndex,
                    itemIndex: itemIndex,
                    childIndex: childIndex,
                    objectKey: objectKey,
                    value: 0,
                  })
                );
              }
              dispatch(
                actionPostComponentSettingEdit({
                  parents: parents,
                  property: property,
                  componentIndex: componentIndex,
                  itemIndex: itemIndex,
                  childIndex: childIndex,
                  objectKey: objectKey,
                  value: value,
                })
              );
              if (setType) {
                dispatch(
                  actionPostComponentSettingEdit({
                    parents: parents,
                    property: typeKey,
                    componentIndex: componentIndex,
                    itemIndex: itemIndex,
                    childIndex: childIndex,
                    value: e['source-type'],
                  })
                );
              }
              if (setDataType) {
                dispatch(
                  actionPostComponentSettingEdit({
                    parents: parents,
                    property: dataTypeKey,
                    componentIndex: componentIndex,
                    itemIndex: itemIndex,
                    childIndex: childIndex,
                    value: e?.['data-type'],
                  })
                );
              }

              if (setDataSource) {
                dispatch(
                  actionPostComponentSettingEdit({
                    parents: parents,
                    property: dataSourceKey,
                    componentIndex: componentIndex,
                    itemIndex: itemIndex,
                    childIndex: childIndex,
                    value: sourceObj?.type,
                  })
                );
              }

              if (setNestedType) {
                dispatch(
                  actionPostComponentSettingEdit({
                    parents: parents,
                    property: property,
                    componentIndex: componentIndex,
                    itemIndex: itemIndex,
                    childIndex: childIndex,
                    objectKey: typeKey,
                    value: e['source-type'],
                  })
                );
              }
              if (setNestedDataType) {
                dispatch(
                  actionPostComponentSettingEdit({
                    parents: parents,
                    property: property,
                    componentIndex: componentIndex,
                    itemIndex: itemIndex,
                    childIndex: childIndex,
                    objectKey: nestedDataTypeKey,
                    value: e?.['data-type'],
                  })
                );
              }
            } else {
              setValue('');
              if (!setNestedType) {
                // set's default to an empty string
                dispatch(
                  actionPostComponentSettingEdit({
                    parents: parents,
                    property: property,
                    componentIndex: componentIndex,
                    itemIndex: itemIndex,
                    childIndex: childIndex,
                    value: '',
                  })
                );
                if (setType) {
                  // set's type at the same level as property
                  dispatch(
                    actionPostComponentSettingEdit({
                      parents: parents,
                      property: typeKey,
                      componentIndex: componentIndex,
                      itemIndex: itemIndex,
                      childIndex: childIndex,
                      value: '',
                    })
                  );
                }
              }
              if (setNestedType) {
                // sets default field value
                dispatch(
                  actionPostComponentSettingEdit({
                    parents: parents,
                    property: property,
                    componentIndex: componentIndex,
                    itemIndex: itemIndex,
                    childIndex: childIndex,
                    value: item.defaultValue,
                  })
                );
                // set's nested type i.e dimension or measure
                dispatch(
                  actionPostComponentSettingEdit({
                    parents: parents,
                    property: property,
                    componentIndex: componentIndex,
                    itemIndex: itemIndex,
                    childIndex: childIndex,
                    objectKey: 'type',
                    value: '',
                  })
                );
              }
            }
          }}
        >
          {selectOptions}
        </Select>
      </Col>
    </Row>
  );
};

export { DimensionInput };
