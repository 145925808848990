/* eslint-disable default-case */
import React from 'react';
import { Source, Layer } from 'react-map-gl';
import {
  PM_TILES_CDN_URL,
  SOURCES_API,
  QUERY_API,
} from '../../../../../configs/env';
import {
  getMinMaxZoom,
  getFilters,
  getLayerVisible,
  getHalo,
} from '../../helpers';
import { buildLayerQueryObj, generateJWTToken } from '../../../utils';
import { AkukoAPIService } from '../../../../../services/serviceClass';
import { MapLayer } from '../../../../../configs/component-types';
import { ERROR_GENERIC } from '../../../../../configs/constants';
import { actionPostComponentErrorAdd } from '../../../actions';
import { message } from 'antd';
import { Dictionary } from '@onaio/utils';

export const allMapsLoaded = (maps: Dictionary) => {
  return Object.keys(maps).every((d) => maps?.[d]?.isStyleLoaded());
};

export const hasRequiredFilters = (groupBy: any, filters: any) => {
  const array: any = [];
  if (groupBy && filters) {
    groupBy.forEach((field: any) => {
      filters.forEach((filter: any) => {
        if (field === filter[1]) {
          const obj = {
            field: field,
            filterValue: filter[2],
            multiple: false,
          };
          if (Array.isArray(filter[2])) {
            obj.multiple = true;
          }
          array.push(obj);
        }
      });
    });
  }
  return array;
};

export const getFilterValues = (array: any) => {
  const values = {
    order: array,
  };
  array.forEach((item: any) => {
    (values as any)[item.field] = [];
    if (item.multiple === false) {
      (values as any)[item.field] = `${item.field}[${item.filterValue}].`;
    }
    if (item.multiple === true) {
      item.filterValue.forEach((value: any) => {
        let p = '';
        p += `${item.field}[${value}].`;
        (values as any)[item.field].push(p);
      });
    }
  });
  return values;
};

export const getProperties = (values: any) => {
  const propertyCount = getPropertyCount(values);
  const fieldCount = values.order.length;
  const properties: any = [];
  if (propertyCount > 0) {
    for (let i = 0; i < propertyCount; i++) {
      let property = '';
      for (let f = 0; f < fieldCount; f++) {
        property += `field_${f}_name[field_${f}_value].`;
      }
      properties.push(property);
    }
    values.order.forEach((item: any, index: any) => {
      if (item.multiple === true) {
        properties.forEach((property: any, propertyIndex: any) => {
          let filterValue;
          if (item.filterValue[propertyIndex]) {
            filterValue = item.filterValue[propertyIndex];
          } else {
            filterValue = item.filterValue[0];
          }
          const p = property
            .replace(`field_${index}_name`, item.field)
            .replace(`field_${index}_value`, filterValue);
          properties[propertyIndex] = p;
        });
      }
      if (item.multiple === false) {
        properties.forEach((property: any, propertyIndex: any) => {
          const p = property
            .replace(`field_${index}_name`, item.field)
            .replace(`field_${index}_value`, item.filterValue);
          properties[propertyIndex] = p;
        });
      }
    });
  }
  return properties;
};

export const getPropertyCount = (values: any) => {
  let count = 0;
  values.order.forEach((item: any) => {
    if (item.multiple === true) {
      count = count + item.filterValue.length;
    }
  });
  if (count === 0) {
    count = 1;
  }
  return count;
};

export const getGroupByLabelExpression = (
  label: any,
  layer: any,
  props: any
) => {
  // get groupBy fields from geometry
  const groupBy =
    props.postSources[layer.source].geometries[layer.geometryIndex].groupBy;

  // get layer filters
  const filters = layer.filters;

  // ensure required filters
  const currentFilters = hasRequiredFilters(groupBy, filters);

  // get filter values
  const filterValues = getFilterValues(currentFilters);

  // generate properties
  const properties = getProperties(filterValues);

  if (properties.length === 0) {
    properties.push('4353453453443');
  }

  let field = layer.symbolField;
  if (layer.labels) {
    field = label.symbolField;
  }

  if (label.symbolField) {
    const conditions: any = [];
    properties.forEach((property: any) => {
      conditions.push(
        ['boolean', ['has', `${property}${field}`], true],
        [
          'concat',
          label.symbolPrefix,
          ['to-string', ['get', `${property}${field}`]],
          ['concat', label.symbolSuffix],
        ]
      );
    });

    const expression = ['case', ...conditions, ''];
    const symbolSize = label.symbolSize || 12;

    return {
      'text-field': expression,
      'text-size': [
        'interpolate',
        ['linear'],
        ['zoom'],
        1,
        0,
        2,
        symbolSize,
        24,
        ['*', symbolSize, 6],
      ],
      'text-offset': [
        Number(label.symbolOffsetX) || 0,
        Number(label.symbolOffsetY) || 0,
      ],
      'text-font': [label.symbolFontFamily || 'Arial Unicode MS Bold'],
      'text-allow-overlap': false,
    };
  }
};

export const getGroupByColorExpression = (layer: any, props: any): any => {
  // get groupBy fields from geometry
  const groupBy =
    props.postSources[layer.source].geometries[layer.geometryIndex].groupBy;

  if (!layer.colorField) {
    return layer.fillColor || '#444';
  }

  // get layer filters
  const filters = layer.filters;

  // ensure required filters
  const currentFilters = hasRequiredFilters(groupBy, filters);

  // get filter values
  const filterValues = getFilterValues(currentFilters);

  // generate properties
  const properties = getProperties(filterValues);

  if (properties.length === 0) {
    properties.push('4353453453443');
  }

  const field = layer.colorField;

  // group by interpolation
  if (layer.colorField && layer.colorMethod === 'interpolate') {
    const fillFieldMinValue = layer.fillFieldMinValue || 0;
    const fillFieldMaxValue = layer.fillFieldMaxValue || 100;
    const fillFieldStartColor = layer.fillFieldStartColor
      ? hexToRgba(layer.fillFieldStartColor)
      : layer.fillFieldStartColor || '#ddd';
    const fillFieldEndColor = layer.fillFieldEndColor
      ? hexToRgba(layer.fillFieldEndColor)
      : layer.fillfieldendColor || '#444';

    const conditions: any = [];
    properties.forEach((property: any) => {
      conditions.push(
        ['boolean', ['has', `${property}${field}`], true],
        [
          'interpolate',
          ['linear'],
          ['to-number', ['get', `${property}${field}`]],
          Number(fillFieldMinValue),
          fillFieldStartColor,
          Number(fillFieldMaxValue),
          fillFieldEndColor,
        ]
      );
    });

    const exp = ['case', ...conditions, 'rgba(0,0,0,0)'];
    return exp;
  }

  // group by custom steps
  if (
    layer.colorField &&
    layer.colorMethod === 'custom' &&
    layer.colorMode === 'steps'
  ) {
    const steps: any = [];
    if (layer.colorSteps) {
      layer.colorSteps.forEach((item: any) => {
        steps.push(item.color);
        steps.push(Number(item.value));
      });
    }
    const conditions: any = [];
    properties.forEach((property: any) => {
      conditions.push(
        ['boolean', ['has', `${property}${field}`], true],
        [
          'step',
          ['to-number', ['get', `${property}${field}`]],
          ...steps,
          'rgba(0,0,0,0)',
        ]
      );
    });
    const exp = ['case', ...conditions, 'rgba(0,0,0,0)'];
    return exp;
  }

  // group by generated color stops
  if (
    layer.colorField &&
    layer.colorMethod === 'breaks' &&
    layer.colorBreaks?.length > 0
  ) {
    const steps: any = [];
    layer.colorBreaks.forEach((item: any) => {
      steps.push(item.color);
      steps.push(Number(item.value));
    });
    const conditions: any = [];
    properties.forEach((property: any) => {
      conditions.push(
        ['boolean', ['has', `${property}${field}`], true],
        [
          'step',
          ['to-number', ['get', `${property}${field}`]],
          ...steps,
          'rgba(0,0,0,0)',
        ]
      );
    });
    const exp = ['case', ...conditions, 'rgba(0,0,0,0)'];
    return exp;
  }

  // group by custom categories
  if (
    (layer.colorField && layer.colorMethod === 'categorical') ||
    layer.colorMode === 'categories'
  ) {
    const categories: any = [];
    if (layer.colorCategories) {
      layer.colorCategories.forEach((item: any) => {
        categories.push(item.value);
        categories.push(item.color);
      });
    }
    const conditions: any = [];
    properties.forEach((property: any) => {
      conditions.push(
        ['boolean', ['has', `${property}${field}`], true],
        [
          'match',
          ['get', `${property}${field}`],
          ...categories,
          'rgba(0,0,0,0)',
        ]
      );
    });
    const exp = ['case', ...conditions, 'rgba(0,0,0,0)'];
    return exp;
  }

  return '#444';
};

export const getGroupByRadiusExpression = (layer: any, props: any): any => {
  const radius = 10;

  // get groupBy fields from geometry
  const groupBy =
    props.postSources[layer.source].geometries[layer.geometryIndex].groupBy;

  // get groupBy values
  const groupByValues =
    props.postSources[layer.source].geometries[layer.geometryIndex]
      .groupByValues;

  // get layer filters
  const filters = layer.filters;

  // ensure required filters
  const currentFilters = hasRequiredFilters(groupBy, filters);

  // get filter values
  const filterValues = getFilterValues(currentFilters);

  // generate properties
  const properties = getProperties(filterValues);

  // add a default random property, if empty
  if (properties.length === 0) {
    properties.push('4ddd434534545dd3443');
  }

  // set field
  let field = layer.circleRadiusField;
  if (!layer.circleRadiusField && groupByValues) {
    field = groupByValues[0];
  }

  // build the conditional expression
  const conditions: any = [];
  properties.forEach((property: any) => {
    if (layer.circleRadiusField) {
      conditions.push(
        ['boolean', ['has', `${property}${field}`], true],
        [
          '/',
          ['sqrt', ['to-number', ['get', `${property}${field}`]]],
          layer.circleScale,
        ]
      );
    } else {
      conditions.push(
        ['boolean', ['has', `${property}${field}`], true],
        layer.circleRadius || radius
      );
    }
  });
  const exp = ['case', ...conditions, 0];
  return exp;
};

export const getHeightExpression = (layer: any) => {
  // interplolated values
  if (layer.heightField && layer.heightMethod === 'interpolate') {
    const fillFieldMinValue = layer.extrusionFieldMinValue || 0;
    const fillFieldMaxValue = layer.extrusionFieldMaxValue || 100;
    return [
      'interpolate',
      ['linear'],
      ['to-number', ['get', layer.heightField]],
      parseInt(fillFieldMinValue),
      4000,
      parseInt(fillFieldMaxValue),
      400000,
    ];
  }
  // extrusion height based on data field
  if (layer.heightField) {
    return [
      'interpolate',
      ['linear'],
      ['zoom'],
      15,
      0,
      15.05,
      ['get', layer.heightField],
    ];
  }
  return 4000;
};

export const hexToRgba = (hexColor: string) => {
  // Remove the '#' if present
  hexColor = hexColor.replace(/^#/, '');
  // Convert hex to decimal
  const red = parseInt(hexColor.substring(0, 2), 16);
  const green = parseInt(hexColor.substring(2, 4), 16);
  const blue = parseInt(hexColor.substring(4, 6), 16);
  const alpha = parseInt(hexColor.substring(6, 8), 16) / 255.0; // Convert alpha to a value between 0 and 1

  // Return the RGBA values as an object
  return `rgba(${red}, ${green}, ${blue}, ${
    alpha && !isNaN(alpha) ? alpha : 1
  })`; // `rgba(255,0,0)`
};

export const getColorExpression = (layer: any, props: any) => {
  if (
    layer.source &&
    layer.geometries !== null &&
    layer.geometryIndex >= 0 &&
    props.postSources[layer.source] &&
    props.postSources[layer.source].geometries &&
    props.postSources[layer.source].geometries[layer.geometryIndex] &&
    props.postSources[layer.source].geometries[layer.geometryIndex].groupBy &&
    props.postSources[layer.source].geometries[layer.geometryIndex].groupBy[0]
  ) {
    return getGroupByColorExpression(layer, props);
  }
  // interplolated values
  if (layer.colorField !== '' && layer.colorMethod === 'interpolate') {
    const fillFieldMinValue = layer.fillFieldMinValue || 0;
    const fillFieldMaxValue = layer.fillFieldMaxValue || 100;
    const fillFieldStartColor = layer.fillFieldStartColor
      ? hexToRgba(layer.fillFieldStartColor)
      : layer.fillFieldStartColor || '#ddd';
    const fillFieldEndColor = layer.fillFieldEndColor
      ? hexToRgba(layer.fillFieldEndColor)
      : layer.fillfieldendColor || '#444';
    return [
      'interpolate',
      ['linear'],
      ['to-number', ['get', layer.colorField]],
      Number(fillFieldMinValue),
      fillFieldStartColor,
      Number(fillFieldMaxValue),
      fillFieldEndColor,
    ];
  }
  // custom color stops
  if (
    (layer.colorField && layer.colorMethod === 'manual') ||
    (layer.colorMode === 'steps' && layer.colorMethod === 'custom')
  ) {
    const exp = ['step', ['to-number', ['get', layer.colorField]]];
    if (layer.colorSteps) {
      layer.colorSteps.forEach((item: any) => {
        exp.push(item.color ? hexToRgba(item.color) : item.color);
        exp.push(Number(item.value) as any);
      });
    }
    exp.push('rgba(0,0,0,0)');
    return exp;
  }

  // generated color stops
  if (
    layer.colorField &&
    layer.colorMethod === 'breaks' &&
    layer.colorBreaks?.length > 0
  ) {
    const exp = ['step', ['to-number', ['get', layer.colorField]]];
    layer.colorBreaks.forEach((item: any, index: number) => {
      if (Number(layer.colorBreaks[index + 1]?.value)) {
        exp.push(item.color ? hexToRgba(item.color) : item.color);
        exp.push(Number(layer.colorBreaks[index + 1]?.value) as any);
      }
    });
    exp.push('rgba(0,0,0,0)');
    return exp;
  }

  // custom color stops
  if (
    layer.colorField &&
    layer.colorMethod === 'breaks' &&
    layer.colorBreaks?.length
  ) {
    const steps = [];
    if (layer.colorBreaks) {
      layer.colorBreaks.forEach((item: any) => {
        steps.push([
          '<',
          ['to-number', ['get', layer.colorField]],
          Number(item.value) as any,
          item.color ? hexToRgba(item.color) : item.color,
        ]);
      });
    }
    steps.push(
      layer.colorBreaks?.[layer.colorBreaks.length - 1]?.color
        ? hexToRgba(layer.colorBreaks[layer.colorBreaks.length - 1].color)
        : layer.colorBreaks?.[layer.colorBreaks.length - 1]?.color
    );
    return ['case', steps];
  }

  // category matching
  if (
    (layer.colorField && layer.colorMethod === 'categorical') ||
    (layer.colorMode === 'categories' && layer.colorMethod === 'custom')
  ) {
    const exp = ['match', ['to-string', ['get', layer.colorField]]];

    if (layer.colorCategories) {
      layer.colorCategories.forEach((item: any) => {
        exp.push(item.value);
        exp.push(item.color ? hexToRgba(item.color) : item.color);
      });
    }
    if (layer.showOtherCategories && layer.defaultCategoryColors?.length) {
      exp.push(
        layer.defaultCategoryColors[0].color
          ? hexToRgba(layer.defaultCategoryColors[0].color)
          : layer.defaultCategoryColors[0].color
      );
    } else {
      exp.push('rgba(0,0,0,0)');
    }
    return exp;
  }
  if (layer.fillColor) {
    return layer.fillColor ? hexToRgba(layer.fillColor) : layer.fillColor;
  }
  return '#444';
};

export const getRadiusExpression = (layer: any, props: any) => {
  // temporal fill data
  let radius: any = 5;
  // validates this based if radius data type is not set for existing layers specs
  const numberRadiusField =
    layer?.circleRadiusDataType === 'number' || !layer?.circleRadiusDataType;
  if (
    layer.source &&
    layer.geometries &&
    layer.geometryIndex >= 0 &&
    props.postSources[layer.source] &&
    props.postSources[layer.source].geometries[layer.geometryIndex] &&
    props.postSources[layer.source].geometries[layer.geometryIndex].groupBy &&
    props.postSources[layer.source].geometries[layer.geometryIndex].groupBy[0]
  ) {
    radius = getGroupByRadiusExpression(layer, props);
  } else if (
    layer?.circleRadiusField &&
    numberRadiusField &&
    layer?.radiusScale !== 'steps'
  ) {
    radius = [
      '*',
      [
        '/',
        ['sqrt', ['to-number', ['get', layer.circleRadiusField]]],
        ['sqrt', ['to-number', layer.maxValue]],
      ],
      layer.circleRadius,
    ];
  } else if (
    layer?.circleRadiusField &&
    layer?.radiusScale === 'steps' &&
    layer?.radiusSteps?.length
  ) {
    const exp = ['step', ['to-number', ['get', layer.circleRadiusField]]];
    layer.radiusSteps.forEach((item: any, index: number) => {
      if (Number(layer.radiusSteps[index + 1]?.value)) {
        exp.push(item.radius);
        exp.push(Number(layer.radiusSteps[index + 1]?.value) as any);
      }
    });
    exp.push(layer.circleRadius || 1);
    return exp;
  } else if (
    layer?.radiusScale === 'categorical' &&
    layer?.radiusCategories?.length &&
    layer?.categoryCircleRadiusField
  ) {
    const mapboxExpression = [
      'match',
      ['get', layer?.categoryCircleRadiusField], // The property to match against
      ...layer.radiusCategories?.flatMap(({ value, radius }: any) => [
        value,
        Number(radius),
      ]), // Flatten the value-radius pairs
      0, // Default radius if no matches are found
    ];
    return mapboxExpression;
  } else if (layer.circleRadius) {
    radius = ['to-number', layer.circleRadius];
  }

  return [
    'interpolate',
    ['linear'],
    ['zoom'],
    7,
    ['/', radius, 2],
    10,
    radius,
    13,
    ['*', radius, 2],
  ];
};

/**
 * Get the default paint properties for map layer
 *
 * @param {object} layer map layer
 * @returns {object} paint properties
 */
export const getLayerPaint = (layer: any, props: any): any => {
  switch (layer.layerType) {
    case 'fill-extrusion':
      return {
        'fill-extrusion-color': getColorExpression(layer, props),
        'fill-extrusion-height': getHeightExpression(layer) || 4000,
        'fill-extrusion-base': 0,
        'fill-extrusion-opacity': layer.layerOpacity,
      };

    case 'circle':
      return {
        'circle-color': getColorExpression(layer, props),
        'circle-radius': getRadiusExpression(layer, props),
        'circle-blur': layer.circleBlur || 0,
        'circle-opacity': layer.layerOpacity,
        'circle-stroke-color': layer.circleStrokeColor
          ? hexToRgba(layer.circleStrokeColor)
          : layer.circleStrokeColor || '#000000',
        'circle-stroke-width': layer.circleStrokeWidth || 0,
        'circle-stroke-opacity': layer.circleStrokeOpacity || 0.8,
        'circle-pitch-alignment': 'map',
      };

    case 'fill':
      return {
        'fill-color': getColorExpression(layer, props),
        'fill-opacity': layer.layerOpacity,
        'fill-outline-color': layer.fillOutlineColor
          ? hexToRgba(layer.fillOutlineColor)
          : layer.fillOutlineColor || '#000000',
      };

    case 'line':
      return {
        'line-color': getColorExpression(layer, props),
        'line-width': layer.lineWidth || 1,
        'line-blur': layer.lineBlur || 0,
        'line-opacity': layer.layerOpacity,
        'line-gap-width': layer.lineGapWidth || 0,
        'line-dasharray':
          layer.lineDashLength && layer.patternGaps
            ? [parseInt(layer.lineDashLength), parseInt(layer.patternGaps)]
            : [],
      };

    case 'symbol': {
      const { layerOpacity } = layer;

      return {
        'icon-opacity': layerOpacity,
        'icon-color': getIconColor(layer),
      };
    }

    default:
      return {};
  }
};

/**
 * Get the icon color mapbox expression
 *
 * @param {object} layer layer to get color expressions for
 * @returns {Array} mapbox expresion
 */
export const getIconColor = (layer: any) => {
  const { symbolField, symbolCategories, icon, iconColor, symbolMethod } =
    layer;
  if (symbolField && symbolCategories?.length && symbolMethod !== 'simple') {
    const exp = ['match', ['get', symbolField]];
    symbolCategories.forEach((category: any) => {
      exp.push(category.value);
      if (category.color) {
        exp.push(hexToRgba(category.color));
      } else {
        if (category.symbol?.color) {
          exp.push(hexToRgba(category.symbol.color));
        } else if (iconColor) {
          exp.push(hexToRgba(iconColor));
        } else {
          exp.push('#000000');
        }
      }
    });

    exp.push('#000000');

    return exp;
  }
  if (icon) {
    return (
      (iconColor && hexToRgba(iconColor)) ||
      (icon.color && hexToRgba(icon.color)) ||
      '#000000'
    );
  }

  return '#000000';
};

/**
 * Conditional icon expression
 *
 * @param {object} layer layer to get icon expressions for
 * @returns {Array} mapbox expression
 */
export const getIconExpression = (layer: any) => {
  const { symbolField, symbolCategories, icon, symbolMethod } = layer;

  if (symbolField && symbolCategories && symbolMethod !== 'simple') {
    const exp = ['match', ['get', symbolField]];

    symbolCategories.forEach((category: any) => {
      exp.push(category.value);
      exp.push(category.symbol.id);
    });

    exp.push('');

    return exp;
  }

  if (icon) {
    return icon.id;
  }

  return null;
};

/**
 * Get symbol layer layout
 *
 * @param {*} layer
 * @returns
 */
export const getSymbolLayout = (layer: any) => {
  let layout: any = {
    'icon-image': getIconExpression(layer),
    'icon-allow-overlap': layer.iconAllowOverlap || false,
  };

  if (layer.iconSize) {
    layout = {
      ...layout,
      'icon-size': [
        'interpolate',
        ['linear'],
        ['zoom'],
        1,
        0,
        2,
        layer.iconSize,
        24,
        ['*', layer.iconSize, 6],
      ],
    };
  }

  return layout;
};

/**
 * Get mapbox layout object
 *
 * @param {object} layer layer to get layout for
 * @returns {object} mapbox layout object
 */
export const getLayout = (layer: any): any => {
  let layout = { visibility: getLayerVisible(layer) };

  const { layerType } = layer;

  if (layerType === 'symbol') {
    layout = {
      visibility: getLayerVisible(layer),
      ...getSymbolLayout(layer),
    };
  }

  return layout;
};

export const getBeforeLayer = (index: any, layers: any, map: any) => {
  if (layers.length > 0) {
    if (layers[index - 1]?.layerType) {
      return layers[index - 1]?.id;
    } else {
      return null;
    }
  }
  return null;
};

/*
/**
 * Build mapbox layers
 * 
 * */
export const buildLayers = (
  layers: any,
  props: any,
  buildChildLayers: any = false,
  geojson: any,
  sources: any,
  map: any
) => {
  // geojson re-build
  const mapboxLayers: any = [];
  let layerOrder = [...layers];
  layerOrder = layerOrder.length > 0 ? layerOrder : [];
  if (layerOrder) {
    layerOrder.forEach((layer, index) => {
      const layerId = getMapLayerId(layer);
      const renderAsGeojson = layersToRenderAsGeoJSON([layer], sources);
      const compositeGeojsonLayers = [
        ...renderAsGeojson.geojsonJoinLayers,
        ...renderAsGeojson.geojsonPointLayers,
      ];
      if (
        compositeGeojsonLayers.length > 0 &&
        layer.geometries &&
        layer.geometries[layer.geometryIndex]
      ) {
        const sourceKey = `${layer.geometries[layer.geometryIndex].id}-${
          layer.id
        }-${index}`;
        const data = props.data[`layer-${layer.id}`];
        const { type } = layer.geometries[layer.geometryIndex];

        mapboxLayers.push(
          <Source
            key={sourceKey}
            id={sourceKey}
            type='geojson'
            data={props.data[`layer-geojson-${layer.id}`]}
          />
        );

        if (map && layer.layerType && layer.geometryIndex >= 0) {
          const layerConfig = {
            id: `${layer.id}`,
            type: layer.layerType,
            source: sourceKey,
            layout: getLayout(layer),
            paint: getLayerPaint(layer, props),
            beforeId: getBeforeLayer(index, layerOrder, map),
            minzoom: getMinMaxZoom('min', layer),
            maxzoom: getMinMaxZoom('max', layer),
          };
          mapboxLayers.push(<Layer key={`${layerId}`} {...layerConfig} />);
        }
        // build layer labels
        if (layer.visible && layer.labels && layer.labels.length) {
          layer.labels.forEach((label: any, labelIndex: number) => {
            const layerConfig = {
              source: sourceKey,
              layout: getLabelExpression(label, layer, props),
              paint: {
                'text-color': label.symbolColor || '#555',
                'text-halo-width': 0,
              },
              beforeId: getBeforeLayer(index, layerOrder, map),
            };
            mapboxLayers.push(
              <Layer
                key={`${layerId}-${label}-${index}`}
                id={`${layerId}-label-${index}-${labelIndex}`}
                type='symbol'
                {...layerConfig}
              />
            );
          });
        }
      } else if (
        layer.source &&
        layer.geometryIndex >= 0 &&
        layer.geometries &&
        layer.geometries[layer.geometryIndex]
      ) {
        const sourceKey = buildChildLayers
          ? `child-${layer.geometries[layer.geometryIndex].id}-${index}`
          : `${layer.geometries[layer.geometryIndex].id}-${index}`;
        const geometry =
          sources?.[layer.source]?.geometries?.[layer.geometryIndex];
        // eslint-disable-next-line no-console

        if (geometry) {
          let tilesUrl = `${PM_TILES_CDN_URL}${geometry.id}/{z}/{x}/{y}.mvt`;
          if (geometry?.mvtTilesUrl) {
            tilesUrl = `${geometry?.mvtTilesUrl}/{z}/{x}/{y}.mvt`;
          }
          mapboxLayers.push(
            <Source
              maxzoom={geometry?.maxzoom || 0}
              key={sourceKey}
              id={`source-${geometry.id}`}
              type='vector'
              tiles={[tilesUrl]}
            />
          );

          if (layer.layerType) {
            const layerConfig = {
              id: layer.id,
              type: layer.layerType,
              source: `source-${geometry.id}`,
              'source-layer': geometry?.layerName || 'geojsonLayer',
              layout: getLayout(layer),
              paint: getLayerPaint(layer, props),
              beforeId: getBeforeLayer(index, layerOrder, map),
              minzoom: getMinMaxZoom('min', layer),
              maxzoom: getMinMaxZoom('max', layer),
              filter: getFilters(layer),
            };

            mapboxLayers.push(<Layer key={`${layerId}`} {...layerConfig} />);
          }
          // labels require to be the last items on mapboxlayers for hierarchy correctness
          // build layer labels
          if (layer.visible && layer.labels && layer.labels.length) {
            layer.labels.forEach((label: any, labelIndex: number) => {
              const layerConfig = {
                id: `${layerId}-label-${index}`,
                source: `source-${layer.geometries[layer.geometryIndex].id}`,
                'source-layer': geometry?.layerName || 'geojsonLayer',
                layout: getLabelExpression(label, layer, props),
                paint: {
                  'text-color': label.symbolColor || '#555',
                  'text-halo-width': 1,
                  'text-halo-color': getHalo(label.symbolColor) || '#fff',
                },
                beforeId: getBeforeLayer(index, layerOrder, map),
                minzoom: getMinMaxZoom('min', layer),
                maxzoom: getMinMaxZoom('max', layer),
                filter: getFilters(layer),
              };
              mapboxLayers.push(
                <Layer
                  type='symbol'
                  key={`${layerId}-${label}-${index}-${labelIndex}`}
                  {...layerConfig}
                />
              );
            });
          }
        }
      }
    });
  }

  return mapboxLayers;
};

export const getLabelExpression = (label: any, layer: any, props: any): any => {
  // if groupBy
  if (
    layer.source &&
    layer.geometries &&
    layer.geometryIndex >= 0 &&
    props.postSources[layer.source].geometries[layer.geometryIndex] &&
    props.postSources[layer.source].geometries[layer.geometryIndex].groupBy &&
    props.postSources[layer.source].geometries[layer.geometryIndex].groupBy[0]
  ) {
    return getGroupByLabelExpression(label, layer, props);
  }

  const symbolSize = label.symbolSize || 12;

  if (label.symbolField) {
    const expression = [
      'case',
      ['has', label.symbolField],
      [
        'concat',
        label.symbolPrefix,
        ['to-string', ['get', label.symbolField]],
        ['concat', label.symbolSuffix],
      ],
      '',
    ];
    return {
      'text-field': expression,
      'text-size': [
        'interpolate',
        ['linear'],
        ['zoom'],
        1,
        0,
        2,
        symbolSize,
        24,
        ['*', symbolSize, 6],
      ],
      'text-offset': [
        Number(label.symbolOffsetX) || 0,
        Number(label.symbolOffsetY) || 0,
      ],
      'text-font': [label.symbolFontFamily || 'Arial Unicode MS Bold'],
      'text-allow-overlap': label.iconAllowOverlap || false,
    };
  } else {
    return {};
  }
};

/**
 * Get all icons from all symbol layers
 *
 * @param {Array} layers map layers
 * @returns {Array} icons from all layers
 */
export const getLayerIcons = (layers: any) => {
  let icons: any = [];

  layers.forEach((layer: any) => {
    if (
      layer.symbolField &&
      layer.symbolCategories &&
      layer.symbolMethod !== 'simple'
    ) {
      layer.symbolCategories.forEach((category: any) => {
        const { symbol } = category;

        icons.push({
          id: symbol.id,
          src: symbol.src,
        });
      });
    } else if (layer.icon) {
      icons.push({
        id: layer.icon.id,
        src: layer.icon.src,
      });
    }
  });

  return icons;
};

export const getMapLayerId = (layer: any) =>
  `layer-${layer.id}-${layer.layerType}`;

export const layersToRenderAsGeoJSON = (layers: any, sources: any) => {
  const geojsonJoinLayers: any = [];
  const geojsonPointLayers: any = [];
  layers?.forEach((layer: any) => {
    if (
      layer.geometryIndex >= 0 &&
      layer.geometryIndex !== null &&
      layer.geometries &&
      layer.geometries.length > 0 &&
      sources
    ) {
      const geometry =
        sources?.[layer.source]?.geometries?.[layer.geometryIndex];
      if (geometry?.renderAsGeojson === true && geometry?.type === 'join') {
        return geojsonJoinLayers.push(layer);
      }
      if (geometry?.renderAsGeojson === true && geometry?.type === 'latLng') {
        return geojsonPointLayers.push(layer);
      }
    }
  });
  const geojsonLayers = {
    geojsonJoinLayers: geojsonJoinLayers,
    geojsonPointLayers: geojsonPointLayers,
  };
  return geojsonLayers;
};

const getUniqueListOfGeojsons = (
  geojsonLayers: MapLayer[],
  postSources: any
) => {
  return new Promise((resolve, reject) => {
    const obj = {};
    const array: any = [];
    if (geojsonLayers.length === 0) {
      resolve([]);
    }
    geojsonLayers.forEach((layer: any, index: number) => {
      if (
        postSources[layer.source].geometries?.[layer.geometryIndex]
          ?.renderAsGeojson
      ) {
        (obj as any)[
          postSources[layer.source].geometries?.[layer.geometryIndex].id
        ] = {};
      }
      if (index + 1 === geojsonLayers.length) {
        Object.keys(obj).forEach((key) => {
          array.push(key);
        });
        resolve(array);
      }
    });
  });
};

export const checkPreExistingGeojsons = (
  geojsonLayers: MapLayer[],
  post: any
): MapLayer[] => {
  return geojsonLayers.filter((layer) => {
    return !post.data[`layer-geojson-${layer.id}`];
  });
};

export const getRawGeojsons = async (
  geojsonLayers: any,
  postSources: any,
  post: any,
  action: any
) => {
  const nonPreExistingGeojsons = checkPreExistingGeojsons(geojsonLayers, post);
  const filesToFetch: any = await getUniqueListOfGeojsons(
    nonPreExistingGeojsons,
    postSources
  );
  const promises: any = [];
  return new Promise((resolve: any, reject: any) => {
    if (filesToFetch.length === 0) {
      resolve();
    }

    filesToFetch.forEach((id: any) => {
      const service = new AkukoAPIService(SOURCES_API, 'geometry');
      promises.push(service.read(id));
    });
    Promise.all(promises).then((res) => {
      res.forEach((item, index) => {
        action({ id: `geometry-${filesToFetch[index]}`, geojson: item });
      });
      resolve();
    });
  });
};

export const getDataForLayers = (
  component: any,
  layers: any,
  sources: any,
  measureActionSetter: any,
  limit = 50000,
  dispatch?: any
) => {
  return new Promise((resolve, reject) => {
    const promises: any = [];
    layers.forEach((layer: any) => {
      promises.push(
        fetchLayerData(
          component,
          layer,
          sources[layer.source],
          measureActionSetter,
          (limit = 50000),
          dispatch
        )
      );
    });
    Promise.all(promises).then((res) => {
      resolve(res);
    });
  });
};

/**
 * Fetch bounds dimension from cube
 * @param {*} component Map component
 */
export const getBounds = (
  filterName: string,
  filterValue: string,
  sourceId: string,
  cube: string,
  refreshKey: string,
  boundsField?: string
): Promise<string> => {
  return new Promise(async (resolve, reject) => {
    const queryObj = {};
    if (queryObj) {
      const query = {
        dimensions: [`${cube}.${boundsField ? boundsField : '_bounds'}`],
        filters: [
          {
            member: `${cube}.${filterName}`,
            operator: 'equals',
            values: [filterValue],
          },
        ],
      };

      const token = await generateJWTToken({
        sourceId: sourceId,
        cubeName: cube,
        refreshKey: refreshKey,
      });
      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      };
      const service = new AkukoAPIService(
        QUERY_API,
        '/cubejs-api/v1/load',
        undefined,
        headers
      );
      service
        .create({
          query: query,
        })
        .then((res) => {
          const response = res as Dictionary;
          const queryResult = response.data as Dictionary[];
          resolve(
            queryResult[0]?.[`${cube}.${boundsField ? boundsField : '_bounds'}`]
          );
        })
        .catch((error) => {
          message.error(error?.message || ERROR_GENERIC);
          reject(ERROR_GENERIC);
        });
    }
  });
};

/**
 * Retrieves the latitude and longitude coordinates for a given set of filter
 * NB* This will work for a single filter value.
 *
 * @param {string} filterName - The name of the filter to apply.
 * @param {string} filterValue - The value of the filter to apply.
 * @param {string} sourceId - The ID of the data source.
 * @param {string} cube - The name of the cube.
 * @param {string} refreshKey - The refresh key for the data source.
 * @param {string} lat - The name of the latitude dimension.
 * @param {string} lng - The name of the longitude dimension.
 * @return {Promise<number[]>} A promise that resolves to an array containing the latitude and longitude coordinates.
 */
export const getLatLng = (
  filterName: string,
  filterValue: string,
  sourceId: string,
  cube: string,
  refreshKey: string,
  lat: string,
  lng: string
): Promise<number[]> => {
  return new Promise(async (resolve, reject) => {
    const queryObj = {};
    if (queryObj) {
      const query = {
        dimensions: [`${cube}.${lat}`, `${cube}.${lng}`],
        filters: [
          {
            member: `${cube}.${filterName}`,
            operator: 'equals',
            values: [filterValue],
          },
        ],
      };

      const token = await generateJWTToken({
        sourceId: sourceId,
        cubeName: cube,
        refreshKey: refreshKey,
      });
      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      };
      const service = new AkukoAPIService(
        QUERY_API,
        '/cubejs-api/v1/load',
        undefined,
        headers
      );
      service
        .create({
          query: query,
        })
        .then((res) => {
          const response = res as Dictionary;
          const queryResult = response.data as Dictionary[];
          resolve([
            queryResult[0]?.[`${cube}.${lng}`],
            queryResult[0]?.[`${cube}.${lat}`],
          ]);
        })
        .catch((error) => {
          message.error(error?.message || ERROR_GENERIC);
          reject(ERROR_GENERIC);
        });
    }
  });
};

/**
 * Component to fetch map data from cube
 * @param {*} component Map component
 * @param {*} layer layer to be rendered as geojson
 * @param {*} source source id for the layer
 * @param {*} limit query limit
 * @returns promise
 */
export const fetchLayerData = (
  component: any,
  layer: any,
  source: any,
  measureActionSetter: any,
  limit = 50000,
  dispatch: any
) => {
  return new Promise(async (resolve, reject) => {
    const queryObj =
      layer && source?.uuid
        ? buildLayerQueryObj(
            source,
            layer,
            source.uuid,
            limit,
            source?.refresh_key,
            source?.cube
          )
        : null;
    if (queryObj) {
      if (component?.id !== undefined) {
        const token = await generateJWTToken({
          sourceId: source?.uuid,
          cubeName: source?.cube,
          refreshKey: source?.refresh_key,
        });
        const headers = {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        };
        const service = new AkukoAPIService(
          QUERY_API,
          '/cubejs-api/v1/load',
          undefined,
          headers
        );
        service
          .create({
            query: queryObj?.query,
          })
          .then((res) => {
            const response = res as Dictionary;
            const queryResult = response.data as Dictionary[];
            dispatch(measureActionSetter(queryResult, layer, queryObj, source));
            resolve(queryResult);
          })
          .catch((error) => {
            dispatch(
              actionPostComponentErrorAdd({
                id: layer?.id,
                name: layer?.name,
                type: layer?.type,
                errors: error,
              })
            );
            reject(error);
          });
      }
    }
  });
};

export const getGeojsonLayers = (
  data: any,
  layers: any,
  sources: any,
  action: any,
  setDataIsReady: (setDataIsReady: boolean) => void
) => {
  return new Promise((resolve: any, reject) => {
    const promises: any = [];
    layers.forEach((layer: any) => {
      if (
        sources[layer.source].geometries[layer.geometryIndex]?.type === 'join'
      ) {
        promises.push(
          geojsonJoin(
            data[`layer-${layer.id}`],
            data[
              `geometry-${
                sources[layer.source].geometries[layer.geometryIndex].id
              }`
            ],
            layer,
            sources,
            action
          )
        );
      }
      if (
        sources[layer.source].geometries[layer.geometryIndex]?.type === 'latLng'
      ) {
        promises.push(
          buildPointFeatures(data[`layer-${layer.id}`], layer, action)
        );
      }
    });
    Promise.all(promises).then(() => {
      resolve();
      setDataIsReady(true);
    });
  });
};

/**
 *
 * @param {array} data data coming from cube
 * @param {object} geojson geojson coming from s3
 * @param {object} layer layer to build pointfeatures for
 * @param {object} sources
 * @returns promise
 */
export const geojsonJoin = (
  data: any,
  geojson: any,
  layer: any,
  sources: any,
  action: any
) => {
  return new Promise((resolve, reject) => {
    const features: any = [];
    const { cube } = sources[layer.source];
    const { sourceJoin } =
      sources[layer.source].geometries[layer.geometryIndex];
    const regionsOnQuery = data?.map(
      (record: any) => record[`${cube}.${sourceJoin}`]
    );
    const { id } = layer.geometries[layer.geometryIndex];
    geojson?.features.forEach((feature: any) => {
      if (
        String(feature.properties[sourceJoin]) &&
        regionsOnQuery.includes(feature.properties[sourceJoin])
      ) {
        const propsFromQuery = data.find((record: any) => {
          return (
            String(feature.properties[sourceJoin]).toLowerCase() ===
            String(record[`${cube}.${sourceJoin}`]).toLowerCase()
          );
        });
        const cubePropsList: any = [];
        [propsFromQuery].map((record) => {
          if (record) {
            Object.keys(record).map((recordKey) => {
              // remove the cube pre-fix
              const keyWithoutPrefix = recordKey.split('.')[1];
              const prop = {};
              (prop as any)[keyWithoutPrefix] = record[recordKey];
              // push feature properties to a list
              cubePropsList.push(prop);
            });
          }
        });
        // merge cube object properties
        const compositeCubeProps = Object.assign({}, ...cubePropsList);
        // merge feature properties with cube properties

        const regionFeature = {
          ...feature,
          properties: {
            ...feature.properties,
            ...compositeCubeProps,
          },
        };
        features.push(regionFeature);
      }
    });
    const fc = {
      type: 'FeatureCollection',
      features: features,
    };
    action({ geojson: fc, layerId: layer.id });
    resolve(fc);
  });
};

/**
 *
 * @param {array} data data coming from cube
 * @param {object} layer layer to build pointfeatures for
 * @returns promise
 */
export const buildPointFeatures = (data: any, layer: any, action: any) => {
  return new Promise((resolve, reject) => {
    const features: any = [];
    data.forEach((row: any) => {
      const rowData = {};
      Object.keys(row).map((key) => {
        (rowData as any)[key.split('.')[1]] = row[key];
      });

      // eslint-disable-next-line no-debugger
      if (Object.keys(row)?.length) {
        const cube = Object.keys(row)[0].split('.')[0];
        const lng = row[`${cube}.${layer.geometries[layer.geometryIndex].lng}`];
        const lat = row[`${cube}.${layer.geometries[layer.geometryIndex].lat}`];
        if (lng && lat) {
          const feature = {
            type: 'Feature',
            properties: rowData,
            geometry: {
              type: 'Point',
              coordinates: [
                parseFloat(
                  row[`${cube}.${layer.geometries[layer.geometryIndex].lng}`]
                ),
                parseFloat(
                  row[`${cube}.${layer.geometries[layer.geometryIndex].lat}`]
                ),
              ],
            },
          };

          features.push(feature);
        }
      }
    });
    const fc = { type: 'FeatureCollection', features: features };
    action({ geojson: fc, layerId: layer.id });
    resolve(fc);
  });
};

export const interactiveLayers = (layers: MapLayer[]): string[] | undefined => {
  const layerIds = layers
    .filter((layer: MapLayer) => layer.hasPopup)
    .map((d) => d.id);
  return layerIds.length > 0 ? layerIds : undefined;
};

export const getUpdatedLayer = (
  layers: MapLayer[],
  prevLayers: MapLayer[] | undefined
): MapLayer[] => {
  return layers.filter(
    (layer: MapLayer, index: number) =>
      layer.filters !== prevLayers?.[index]?.filters ||
      layer.source !== prevLayers?.[index]?.source
  );
};
